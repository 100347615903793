<template>
  <div style="display: flex">
    <navigation index="storeHome">
      <div style="width: 100%">
        <div id="thisMonth" style="width: 100%; height: 300px"></div>
      </div>
    </navigation>
  </div>
</template>
    <script>
import axios from "../../commons/AxiosMethod.js";
import navigation from "../../components/Modules/CompanyNavigation.vue";
import { ElMessageBox, ElMessage, UploadProps } from "element-plus";
import { CloseBold, Plus, Delete } from "@element-plus/icons-vue";
import * as echarts from "echarts";

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      BusinessVolume: {
        OrderTotal: "",
        PartOrderTotal: "",
        DayOrderTotal: "",
      },
      BusinessVolumeWhere: {},
      OrderCount: {},
      TrendBusinessVolumeWhere: {},
      TrendBusinessVolume: {},
      UserInfo: {},

      headersImage: {},
      UploadPictureImageUrl: "",
    };
  },
  name: "App",
  mounted() {
 
    this.GetRepairsQuantityReport();

    this.UploadPictureImageUrl = axios.GetUrl() + "/api/Upload/ImageFileWebp";
    this.UploadFullImageFileUrl =
      axios.GetUrl() + "/api/Upload/FullImageFileWebp";

    this.headersImage = {
      Authorization: localStorage.getItem("Authentication_Header"),
    };
  },

  components: {
    navigation,
    Plus,
  },
  methods: {
    GetRepairsQuantityReport() {
      axios.apiMethod(
        "/report/Company/GetRepairsQuantityReport",
        "get",
        {},
        (result) => {
          var chartDom = document.getElementById("thisMonth");
          var myChart = echarts.init(chartDom);

          var option = {
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "shadow",
              },
            },
            xAxis: {
              type: "category",
              data: result.Data.data,
            },
            yAxis: {
              type: "value",
            },
            series: [],
          };

          for (var i = 0; i < result.Data.Items.length; i++) {
            option.series.push( {
                data: result.Data.Items[i].SeriesDataItems,
                type: "line",
                name: result.Data.Items[i].StoreName,
              } );
 
          }

 
          myChart.setOption(option);
        }
      );
    },
    GetMyUserInfo() {
      axios.apiMethod("/stores/User/GetMyUserInfo", "get", {}, (result) => {
        this.UserInfo = result.Data;
      });
    },
    UploadProductSuccess(response, file, fileList) {
      if (response.Code == 200) {
        this.ComImageUrl = response.Data.FileName;
        this.ComImageFormet = response.Data.ImageFormet;
        this.UserInfo.ProfilePhotoFormat = response.Data.ImageFormet;
        this.UserInfo.ProfilePhoto = response.Data.FileName;
      }
    },

    // 修改个人信息
    UpdateUserInfo() {
      axios.apiMethod(
        "/stores/User/UpdateUserInfo",
        "post",
        this.UserInfo,
        (result) => {
          if (result.Data.IsSuccess) {
            ElMessage({
              title: "提示",
              message: "操作成功",
              type: "success",
            });
          }
        }
      );
    },
  },
};
</script>
    
    <style scoped>
.flex-grow {
  flex-grow: 1;
}

.avatar-uploader .avatar {
  width: 128px;
  height: 128px;
  display: block;
}

.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.avatar-uploader-icon {
  border: 1px dashed #666;
  font-size: 28px;
  color: #8c939d;
  width: 128px;
  height: 128px;
  text-align: center;
}
</style>
    